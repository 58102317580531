<template>
  <div>
    <v-app>
      <v-navigation-drawer
        style="margin-top: 55px"
        width="100%"
        v-model="drawer"
        app
        fixed
        overlay-opacity="0"
        flat
        size="22px !important"
      >
        <v-list nav dense style="padding: 8px 0">
          <v-list-item-group v-model="group">
            <v-list-group :value="true" no-action>
              <template v-slot:activator size="22px !important">
                <v-list-item-icon>
                  <v-icon size="22px !important">mdi-tag</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title @click="$router.push('/productos')">
                    Aplicaciones
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(app, i) in localAreas.items"
                :key="i"
                @click="goToElement(app.url)"
              >
                <v-list-item-title v-text="app.title"></v-list-item-title>
                <v-list-item-icon> </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-item href="https://dev.web-shop4.ledpack.cl/">
              <v-list-item-icon>
                <v-icon size="22px !important">mdi-shopping</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tienda</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="22px !important">mdi-note-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Recursos</v-list-item-title>
            </v-list-item>
            <v-list-group :value="true" no-action>
              <template v-slot:activator size="22px !important">
                <v-list-item-icon>
                  <v-icon size="22px !important">mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Idioma </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(item, i) in globalLanguages.items"
                :key="i"
                @click="changeLang(item)"
              >
                <v-list-item-title @click="close">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-col class="pa-0">
        <v-app-bar color="white" flat app fixed>
          <v-app-bar-nav-icon
            color="#021f4a"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-row style="padding-left: 25%" no-gutters>
            <v-toolbar-title>
              <v-img
                @click="$router.push('/')"
                class="logoSicom"
                src="@/assets/SICOM2020.png"
              ></v-img>
            </v-toolbar-title>
          </v-row>
          <v-col cols="auto" class="search-btn-margin pa-0">
            <v-btn
              v-if="!active"
              @click="active = true"
              class="search-btn"
              text
              icon
            >
              <eva-icon
                name="search"
                animation="pulse"
                fill="rgb(49,97,162)"
              ></eva-icon>
            </v-btn>
          </v-col>
        </v-app-bar>
        <v-col cols="12" v-if="active" class="pt-0 search-textfield">
          <v-text-field
            dense
            v-if="active"
            v-click-outside="onClickOutside"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-col>
      <router-view />
    </v-app>
  </div>
</template>
<script>
export default {
  props: {
    hideLang: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data: () => ({
    drawer: null,
    group: null,
    active: false,
    search: "",
    currentLang: "Español",
    products: [
      ["Camaras", "mdi-camera", "Camera"],
      ["Video Timbres", "mdi-bell-ring", "Ring"],
      ["Smart NVR", "mdi-crop-square", "Collections"],
    ],
  }),
  computed: {
    localAreas() {
      return {
        title: this.$i18n.t("FooterLocalArea"),
        items: [
          {
            id: 3,
            title: this.$i18n.t("FooterLocalAreaOne"),
            url: "/application/3",
          },
          {
            id: 1,
            title: this.$i18n.t("FooterLocalAreaTwo"),
            url: "/application/1",
          },
          {
            id: 2,
            title: this.$i18n.t("FooterLocalAreaThree"),
            url: "/application/2",
          },
        ],
      };
    },
    globalLanguages() {
      return {
        items: [
          { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
          { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
        ],
      };
    },
    responsiveLangText() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    goToElement: function(element) {
      console.log("element: ", element);

      this.$router
        .push(element)
        .then(() => {
          this.$router.go(0);
        })
        .catch((err) => console.log(err));
    },
    close() {
      this.drawer = null;
    },
    changeLang(lang) {
      this.$i18n.locale = lang.value;
      const arrLang = [
        { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
        { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
      ];
      const language = arrLang.find((item) => item.value === lang.value);
      this.currentLang = language.title;
    },
    onClickOutside() {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.logoSicom {
  cursor: pointer;
  width: 120px;
  height: 45px;
}
.language-btn {
  text-transform: capitalize;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.web-language-icon {
  margin-top: 2px;
  margin-right: 2px;
  font-size: 22px !important;
}
.language-text {
  font-family: DM Sans !important;
  font-weight: 700;
  font-size: 16px !important;
  letter-spacing: 0vw;
}
.language-list-item {
  min-height: 48px !important;
}
.language-list {
  border-radius: 0vw !important;
  max-height: auto !important;
}
.language-list-item-title {
  font-family: DM Sans !important;
  font-size: 14px !important;
}
.search-btn {
  padding-top: 3px;
}
.search-btn-margin {
  margin-left: 4px;
  margin-right: 4px;
}
.search-textfield {
  width: 100%;
}
.mdi {
  color: #3b68b2 !important;
  font-size: inherit !important;
}
.mdi:before,
.mdi-set {
  font-size: 22px;
}
</style>
<style scoped>
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: none;
}
</style>
