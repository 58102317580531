<template>
  <v-card
    v-if="!mobile"
    flat
    tile
    class="footer-card-container white--text text-center"
  >
    <v-card-text class="footer-card-text-container">
      <v-row no-gutters>
        <v-spacer></v-spacer>

        <v-col class="pt-0 pb-0 footer-col-content" cols="11">
          <v-row no-gutters class="footer-questions-container">
            <v-col cols="6" class="pb-0 footer-title-container">
              <v-col cols="7" class="pa-0">
                <div
                  class="footer-title-text transition-swing transition-swing"
                  v-text="$t(`FooterTitle`)"
                />
              </v-col>

              <v-spacer />
            </v-col>

            <v-col cols="auto" class="text-left pb-0 footer-app-area-title-col">
              <div
                class="footer-item-title-text transition-swing transition-swing"
                v-text="appAreas.title"
              />

              <v-col class="pl-0 footer-app-area-item-col">
                <a
                  v-for="appArea in appAreas.items"
                  :key="appArea.id"
                  :href="appArea.url ? appArea.url : null"
                  class="text-decoration-none"
                >
                  <div
                    class="footer-item-subtitle-text transition-swing"
                    v-text="appArea.title"
                  />
                </a>
              </v-col>
            </v-col>

            <v-spacer />

            <v-col
              cols="auto"
              class="text-center pb-0 footer-local-area-title-col"
            >
              <div
                class="footer-item-title-text transition-swing"
                v-text="localAreas.title"
              />

              <v-col class="footer-local-area-item-col">
                <a
                  v-for="localArea in localAreas.items"
                  :key="localArea.id"
                  @click="goToElement(localArea.url)"
                  class="text-decoration-none"
                >
                  <div
                    class="footer-item-subtitle-text transition-swing"
                    v-text="localArea.title"
                  />
                </a>
              </v-col>
            </v-col>

            <v-spacer />

            <v-col cols="auto" class="text-right pb-0 footer-others-title-col">
              <div
                class="footer-item-title-text transition-swing"
                v-text="others.title"
              />

              <v-col class="pr-0 footer-others-item-col">
                <a
                  v-for="other in others.items"
                  :key="other.id"
                  :href="other.url ? other.url : null"
                  class="text-decoration-none"
                >
                  <div
                    class="footer-item-subtitle-text transition-swing"
                    v-text="other.title"
                  />
                </a>
              </v-col>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <strong>
              <div
                class="footer-contact-text transition-swing"
                v-text="contacts.title"
              />
            </strong>
            <v-col class="pa-0 footer-contact-item-container">
              <a
                v-for="contact in contacts.items"
                :key="contact.id"
                :href="contact.url ? contact.url : null"
                class="text-decoration-none"
              >
                <v-icon
                  class="footer-contact-item-icon transition-swing"
                  color="white"
                  >{{ contact.icon }}</v-icon
                >
              </a>
            </v-col>
          </v-row>

          <v-row no-gutters class="footer-bottom-container">
            <v-col cols="auto" class="footer-bottom-sicom-content">
              <div class="transition-swing">{{ bottomInfo.title }}</div>
            </v-col>

            <v-col
              cols="auto"
              v-for="(info, index) in bottomInfo.items"
              :key="info.id"
            >
              <v-row no-gutters>
                <a
                  :href="info.url ? info.url : null"
                  class="text-decoration-none"
                >
                  <div
                    class="footer-bottom-item-text transition-swing"
                    v-text="info.text"
                  ></div>
                </a>

                <div v-if="index < bottomInfo.items.length - 1">|</div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card v-else flat tile class="footer-card-container white--text ">
    <v-card-text class="footer-card-text-container">
      <v-row no-gutters>
        <v-col class="pt-0 pb-0 footer-col-content" cols="12">
          <v-row no-gutters class="footer-questions-container text-left ml-3">
            <v-col cols="12" class="pb-0 footer-title-container">
              <v-col cols="12" class="pa-0">
                <div
                  class="footer-title-font transition-swing transition-swing"
                  v-text="$t(`FooterTitle`)"
                />
              </v-col>

              <v-spacer />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-list color="#021f4a" dense>
            <v-divider
              class="ml-4 mr-5 mt-5"
              style="background-color:white"
            ></v-divider>
            <v-list-group
              @click="changeIcon('app')"
              :append-icon="app ? 'mdi-minus' : 'mdi-plus'"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content :ripple="false">
                  <v-list-item-title
                    style="color:white"
                    v-text="appAreas.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :ripple="false"
                v-for="child in appAreas.items"
                :key="child.id"
              >
                <v-list-item-content>
                  <v-list-item-title
                    style="color:white"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider
              class="ml-4 mr-5"
              style="background-color:white"
            ></v-divider>
            <v-list-group
              @click="changeIcon('area')"
              :append-icon="area ? 'mdi-minus' : 'mdi-plus'"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    style="color:white"
                    v-text="localAreas.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :ripple="false"
                v-for="child in localAreas.items"
                :key="child.id"
              >
                <v-list-item-content @click="goToElement(child.url)">
                  <v-list-item-title
                    style="color:white"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider
              class="ml-4 mr-5"
              style="background-color:white"
            ></v-divider>
            <v-list-group
              @click="changeIcon('other')"
              :append-icon="other ? 'mdi-minus' : 'mdi-plus'"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    style="color:white"
                    v-text="others.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :ripple="false"
                v-for="child in others.items"
                :key="child.id"
                :href="child.url ? child.url : null"
              >
                <v-list-item-content>
                  <v-list-item-title
                    style="color:white"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-divider
              class="ml-4 mr-5"
              style="background-color:white"
            ></v-divider>
          </v-list>
        </v-col>
      </v-row>
      <v-row no-gutters class="ml-4 mt-4">
        <v-col cols="4">
          <strong>
            <div
              class="footer-contact-text transition-swing"
              v-text="contacts.title"
            />
          </strong>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="4" class="pa-0 ml-4">
          <a
            v-for="contact in contacts.items"
            :key="contact.id"
            :href="contact.url ? contact.url : null"
            class="text-decoration-none"
          >
            <v-icon
              class="footer-contact-item-icon transition-swing"
              color="white"
              >{{ contact.icon }}</v-icon
            >
          </a>
        </v-col>
      </v-row>
      <v-row no-gutters class="footer-bottom-container ml-4">
        <v-col cols="auto" class="footer-bottom-sicom-content-font">
          <div class="transition-swing">{{ bottomInfo.title }}</div>
        </v-col>

        <v-col
          cols="auto"
          v-for="(info, index) in bottomInfo.items"
          :key="info.id"
        >
          <v-row no-gutters>
            <a :href="info.url ? info.url : null" class="text-decoration-none">
              <div
                class="footer-bottom-item-font transition-swing"
                v-text="info.text"
              ></div>
            </a>

            <div
              style="font-size:10px"
              v-if="index < bottomInfo.items.length - 1"
            >
              |
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    app: false,
    other: false,
    area: false,
  }),

  methods: {
    goToElement: function(element) {
      /*console.log("element: ", element);

      this.$router
        .push(element)
        .then(() => {
          this.$router.go(0);
        })
        .catch((err) => console.log(err));*/
      console.log(element);
    },
    changeIcon(group) {
      console.log(group);

      if (group === "app") {
        this.app = !this.app;
        if (this.other === true) {
          this.other = !this.other;
        } else if (this.area === true) {
          this.area = !this.area;
        }
      } else if (group === "area") {
        this.area = !this.area;
        if (this.other === true) {
          this.other = !this.other;
        } else if (this.app === true) {
          this.app = !this.app;
        }
      } else {
        this.other = !this.other;
        if (this.app === true) {
          this.app = !this.app;
        } else if (this.area === true) {
          this.area = !this.area;
        }
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    appAreas() {
      return {
        title: this.$i18n.t("FooterAplicationAreas"),
        items: [
          {
            title: this.$i18n.t("FooterAplicationAreasOne"),
            id: 0,
          },
          {
            title: this.$i18n.t("FooterAplicationAreasTwo"),
            id: 1,
          },
          {
            title: this.$i18n.t("FooterAplicationAreasThree"),
            id: 2,
          },
          {
            title: this.$i18n.t("FooterAplicationAreasFour"),
            id: 3,
          },
        ],
      };
    },
    localAreas() {
      return {
        title: this.$i18n.t("FooterLocalArea"),
        items: [
          {
            id: 3,
            title: this.$i18n.t("FooterLocalAreaOne"),
            url: "/application/3",
          },
          {
            id: 1,
            title: this.$i18n.t("FooterLocalAreaTwo"),
            url: "/application/1",
          },
          {
            id: 2,
            title: this.$i18n.t("FooterLocalAreaThree"),
            url: "/application/2",
          },
        ],
      };
    },
    others() {
      return {
        title: this.$i18n.t("FooterOther"),
        items: [
          {
            id: 0,
            title: this.$i18n.t("FooterOtherOne"),
            url: "http://resources.sicomelectronics.com/",
          },
          {
            id: 1,
            title: this.$i18n.t("FooterOtherTwo"),
            url: "https://dev.web-shop4.ledpack.cl/",
          },
        ],
      };
    },
    contacts() {
      return {
        title: this.$i18n.t("FooterContact"),
        items: [
          {
            id: "0",
            name: "linkedin",
            url: "",
            icon: "mdi-linkedin",
          },
          {
            id: "1",
            name: "instagram",
            url: "",
            icon: "mdi-instagram",
          },
          {
            id: "2",
            name: "youtube",
            url: "",
            icon: "mdi-youtube",
          },
        ],
      };
    },
    bottomInfo() {
      return {
        title: `© ${new Date().getFullYear()} — Sicom Electronics, Inc.`,
        items: [
          {
            id: "0",
            text: this.$i18n.t("FooterBottomSupport"),
            url: "",
          },
          {
            id: "1",
            text: this.$i18n.t("FooterBottomAboutUs"),
            url: "",
          },
          {
            id: "2",
            text: this.$i18n.t("FooterBottomContact"),
            url: "",
          },
        ],
      };
    },
  },
};
</script>

<style>
.footer-card-container {
  background-color: #021f4a !important;
  padding: 0px !important;
}

.footer-card-text-container {
  padding: 0px !important;
}

.footer-col-content {
  padding-left: 0.625vw !important;
  padding-right: 0.625vw !important;
}

.footer-questions-container {
  margin-top: 4.7915vw !important;
}

.footer-title-container {
  text-align: left !important;
  color: white !important;
  line-height: 1.1 !important;
  padding-left: 0vw !important;
  padding-right: 0vw !important;
  padding-top: 1.25vw !important;
}

.footer-title-text {
  font-family: DM Sans !important;
  font-size: 34px !important;
}

.footer-app-area-title-col {
  padding-top: 1.25vw !important;
  padding-left: 0vw !important;
  padding-right: 0.625vw !important;
}

.footer-item-title-text {
  font-family: DM Sans !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1.146vw !important;
}

.footer-app-area-item-col {
  padding-top: 0.625vw !important;
  padding-bottom: 0.625vw !important;
  padding-right: 0.625vw !important;
}

.footer-item-subtitle-text {
  font-family: DM Sans !important;
  display: block !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18.3px !important;
}

.footer-local-area-title-col {
  padding-top: 1.25vw !important;
  padding-left: 0.625vw !important;
  padding-right: 0.625vw !important;
}

.footer-local-area-item-col {
  padding-top: 0.625vw !important;
  padding-bottom: 0.625vw !important;
  padding-left: 0.625vw !important;
  padding-right: 0.625vw !important;
}

.footer-others-title-col {
  padding-top: 1.25vw !important;
  padding-left: 0.625vw !important;
  padding-right: 0vw !important;
}

.footer-others-item-col {
  padding-top: 0.625vw !important;
  padding-bottom: 0.625vw !important;
  padding-left: 0.625vw !important;
}

.footer-contact-text {
  display: inline-block !important;
  line-height: 1.2 !important;
  border-bottom: 0.1045vw solid red !important;
  padding-bottom: 0.1042vw !important;
  font-family: DM Sans !important;
  color: white !important;
  font-size: 16px !important;
}

.footer-contact-item-container {
  text-align: right !important;
}

.footer-contact-item-icon {
  font-size: 1.25vw !important;
}

.footer-bottom-container {
  color: white !important;
  font-size: 0.7291vw !important;
  margin-top: 5.3766vw !important;
  padding-bottom: 0.8333vw !important;
}

.footer-bottom-sicom-content {
  font-family: "DM Sans";
  font-size: 11px;
}

.footer-bottom-item-text {
  font-family: DM Sans !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  padding-left: 19.2px !important;
  padding-right: 19.2px !important;
}

.spacer {
  flex-grow: 0.5 !important;
}
.mdi-linkedin::before {
  content: "\F033B";
  font-size: 20px;
}
.mdi-instagram::before {
  content: "\F02FE";
  font-size: 20px;
}
.mdi-youtube::before {
  content: "\F05C3";
  font-size: 20px;
}
.footer-contact-text {
  font-size: 14px !important;
}
.footer-contact-item-icon {
  font-size: 16px !important;
}
.v-list .mdi-plus::before {
  content: "\F0415";
  color: white;
  font-size: 20px !important;
}

.v-list .mdi-minus::before {
  content: "\F0374";
  color: white;
  font-size: 20px !important;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: 400;
  line-height: 2rem;
  font-size: 14px;
  font-family: "Dm Sans";
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before
  .theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0 !important;
}
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
.v-application .justify-center {
  justify-content: center !important;
}
.v-list-item--link:before {
  /* background-color: currentColor; */
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 4px 0;
}
@media screen and (max-width: 1600px) {
  .footer-title-text {
    font-family: DM Sans !important;
    font-size: 2.25vw !important;
  }
  .footer-item-title-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 1vw !important;
    line-height: 1.146vw !important;
  }
  .footer-item-subtitle-text {
    font-family: DM Sans !important;
    display: block !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 0.875vw !important;
    line-height: 1.146vw !important;
  }

  .footer-contact-text {
    display: inline-block !important;
    line-height: 1.2 !important;
    border-bottom: 0.1045vw solid red !important;
    padding-bottom: 0.1042vw !important;
    font-family: DM Sans !important;
    color: white !important;
    font-size: 1vw !important;
  }
  .footer-bottom-item-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 0.688vw !important;
    padding-left: 1.2vw !important;
    padding-right: 1.2vw !important;
  }

  .footer-bottom-sicom-content {
    font-family: "DM Sans";
    font-size: 0.688vw;
  }
  .mdi-linkedin::before {
    content: "\F033B";
    font-size: 1.25vw;
  }
  .mdi-instagram::before {
    content: "\F02FE";
    font-size: 1.25vw;
  }
  .mdi-youtube::before {
    content: "\F05C3";
    font-size: 1.25vw !important;
  }
}
@media screen and (max-width: 769px) {
  .footer-title-text {
    font-family: DM Sans !important;
    font-size: 21px !important;
  }
  .footer-item-title-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 15px !important;
  }
  .footer-item-subtitle-text {
    font-family: DM Sans !important;
    display: block !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 15px !important;
  }

  .footer-contact-text {
    display: inline-block !important;
    line-height: 1.2 !important;
    border-bottom: 0.1045vw solid red !important;
    padding-bottom: 0.1042vw !important;
    font-family: DM Sans !important;
    color: white !important;
    font-size: 11px !important;
  }
  .footer-bottom-item-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 9px !important;
    padding-left: 1.2vw !important;
    padding-right: 1.2vw !important;
  }

  .footer-bottom-sicom-content {
    font-family: "DM Sans";
    font-size: 9px !important;
  }
  .mdi-linkedin::before {
    content: "\F033B";
    font-size: 16px !important;
  }
  .mdi-instagram::before {
    content: "\F02FE";
    font-size: 16px !important;
  }
  .mdi-youtube::before {
    content: "\F05C3";
    font-size: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .footer-title-text {
    font-family: DM Sans !important;
    font-size: 2.734vw !important;
  }
  .footer-item-title-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 1.693vw !important;
    line-height: 1.146vw !important;
  }
  .footer-item-subtitle-text {
    font-family: DM Sans !important;
    display: block !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 1.432vw !important;
    line-height: 1.953vw !important;
  }

  .footer-contact-text {
    display: inline-block !important;
    line-height: 1.2 !important;
    border-bottom: 0.1045vw solid red !important;
    padding-bottom: 0.1042vw !important;
    font-family: DM Sans !important;
    color: white !important;
    font-size: 1.432vw !important;
  }
  .footer-bottom-item-text {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 1.172vw !important;
    padding-left: 1.2vw !important;
    padding-right: 1.2vw !important;
  }

  .footer-bottom-sicom-content {
    font-family: "DM Sans";
    font-size: 1.172vw !important;
  }
  .mdi-linkedin::before {
    content: "\F033B";
    font-size: 2.083vw !important;
  }
  .mdi-instagram::before {
    content: "\F02FE";
    font-size: 2.083vw !important;
  }
  .mdi-youtube::before {
    content: "\F05C3";
    font-size: 2.083vw !important;
  }
}

@media screen and (max-width: 426px) {
  .footer-contact-text {
    font-size: 14px !important;
  }
  .footer-card-container {
    background-color: #021f4a !important;
    padding: 0px !important;
  }
  .footer-card-text-container {
    padding: 0px !important;
  }
  .footer-title-font {
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    color: white;
  }
  .footer-col-content {
    padding-left: 0.625vw !important;
    padding-right: 0.625vw !important;
  }

  .v-application .primary--text {
    color: #000 !important;
    caret-color: #fff !important;
  }
  .v-application--is-ltr
    .v-list-group--no-action
    > .v-list-group__items
    > .v-list-item {
    padding-left: 17.5px;
  }

  .v-btn--is-elevated.v-btn--fab {
    box-shadow: 0px 0px 0px 0px;
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 40px;
  }

  .mdi-linkedin::before {
    content: "\F033B";
    font-size: 14px !important;
  }
  .mdi-instagram::before {
    content: "\F02FE";
    font-size: 14px !important;
  }
  .mdi-youtube::before {
    content: "\F05C3";
    font-size: 14px !important;
  }
  .footer-bottom-item-font {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 9px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .footer-bottom-container {
    color: white !important;
    /*font-size: 0.7291vw !important;*/
    margin-top: 20px !important;
    padding-bottom: 10px !important;
  }

  .footer-bottom-sicom-content-font {
    font-family: "DM Sans";
    font-size: 9px;
  }
}
@media screen and (max-width: 424px) {
  .footer-contact-text {
    font-size: 3.286vw !important;
  }
  .footer-contact-item-icon {
    font-size: 3.756vw !important;
  }
  .v-list .mdi-plus::before {
    content: "\F0415";
    color: white;
    font-size: 4.695vw !important;
  }

  .v-list .mdi-minus::before {
    content: "\F0374";
    color: white;
    font-size: 4.695vw !important;
  }

  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    font-weight: 400;
    line-height: 2rem;
    font-size: 3.286vw;
    font-family: "Dm Sans";
  }

  .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before
    .theme--light.v-list-item:hover::before {
    opacity: 0 !important;
  }
  .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }
  .theme--light.v-list-item:hover::before {
    opacity: 0;
  }
  .v-application .justify-center {
    justify-content: center !important;
  }
  .v-list-item--link:before {
    /* background-color: currentColor; */
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .v-list-item--dense .v-list-item__content,
  .v-list--dense .v-list-item .v-list-item__content {
    padding: 0.939vw 0;
  }
  .footer-card-container {
    background-color: #021f4a !important;
    padding: 0px !important;
  }
  .footer-card-text-container {
    padding: 0px !important;
  }
  .footer-title-font {
    font-family: DM Sans !important;
    font-size: 3.756vw !important;
    font-weight: 500;
    color: white;
  }
  .footer-col-content {
    padding-left: 0.625vw !important;
    padding-right: 0.625vw !important;
  }

  .v-application .primary--text {
    color: #000 !important;
    caret-color: #fff !important;
  }
  .v-application--is-ltr
    .v-list-group--no-action
    > .v-list-group__items
    > .v-list-item {
    padding-left: 4.108vw;
  }

  .v-btn--is-elevated.v-btn--fab {
    box-shadow: 0px 0px 0px 0px;
  }

  .mdi-linkedin::before {
    content: "\F033B";
    font-size: 3.286vw !important;
  }
  .mdi-instagram::before {
    content: "\F02FE";
    font-size: 3.286vw !important;
  }
  .mdi-youtube::before {
    content: "\F05C3";
    font-size: 3.286vw !important;
  }
  .footer-bottom-item-font {
    font-family: DM Sans !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 2.113vw !important;
    padding-left: 3.521vw !important;
    padding-right: 3.521vw !important;
  }
  .footer-bottom-container {
    color: white !important;
    /*font-size: 0.7291vw !important;*/
    margin-top: 4.695vw !important;
    padding-bottom: 2.347vw !important;
  }

  .footer-bottom-sicom-content-font {
    font-family: "DM Sans";
    font-size: 2.113vw;
  }
}
</style>
