var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"resources-color-row"},[_c('v-col',{staticClass:"mb-6 resources-col",attrs:{"cols":"11"}},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{class:{
          'text-start': _vm.titleAlignment == 'start',
          'text-center': _vm.titleAlignment == 'center',
        }},[_c('span',{staticClass:"resources-span-title"},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-row',_vm._l((_vm.resourcesShow),function(item,i){return _c('v-col',{key:i,class:{
          'text-start': item.align == 'start',
          'text-center': item.align == 'center',
          'text-end': item.align == 'end',
        },attrs:{"data-aos-once":"false","data-aos":"fade-in","data-aos-duration":"500","data-aos-delay":"550","md":"4","sm":"12"}},[_c('span',{staticClass:"resources-span-text",on:{"click":function($event){return _vm.click(item)}}},[_vm._v(_vm._s(item.details.name))])])}),1),_c('Modal',{ref:"modal",attrs:{"resource":_vm.resourceSelected}}),(!_vm.lastItem)?[_c('v-divider',{staticClass:"mt-16"})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }