<template>
  <v-app style="background-color: #fbfbfb !important;">
    <v-col
      align-self="center"
      style="width:1600px; background-color:white; padding:0px"
    >
      <v-main>
        <Navigation hidemenu v-if="!mobile" />
        <NavigationMobile hidemenu v-else />
        <!-- <Mobile v-else /> -->
      </v-main>
      <Footer />
    </v-col>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import NavigationMobile from "@/components/NavigationMobile.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    NavigationMobile,
    Footer,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Roboto&family=Source+Sans+Pro&display=swap");
html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans" !important;
}
span {
  font-family: "DM Sans" !important;
}
</style>
