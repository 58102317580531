<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :left="true"
      :multi-line="false"
      :timeout="2500"
      :vertical="false"
      :color="color"
    >
      {{ $attrs.message }}

      <template v-slot:action="{ attrs }">
        <v-btn icon dark text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  data: () => ({
    color: "rgb(0,32,79)",
    snackbar: false,
  }),
  computed: {},

  methods: {
    toggleSnack(val) {
      this.snackbar = val;
    },
  },
};
</script>
