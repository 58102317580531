<template>
  <div>
    <v-row no-gutters class="resources-row">
      <v-col
        data-aos-once="false"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="1100"
        cols="12"
        class="resources-col-home"
      >
        <span class="resources-span">{{ $t("Contact_Title") }}</span>
      </v-col>
    </v-row>

    <v-row style=" border-radius: 25px;" class="resources-row-top"></v-row>

    <v-row justify="center" style="background-color:#FFFFFF">
      <v-col align="center" cols="4">
        <v-spacer class="contact-spacer"></v-spacer>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            outlined
            dense
            :counter="20"
            :rules="Rules.nameRules"
            :label="$t('Contact_Name')"
            :color="color"
            required
          ></v-text-field>
          <v-text-field
            v-model="email"
            outlined
            dense
            :rules="Rules.emailRules"
            :label="$t('Contact_Email')"
            :color="color"
            required
          ></v-text-field>
          <v-select
            readonly
            outlined
            dense
            v-model="site"
            :items="sites"
            :color="color"
            :label="$t('Contact_Web')"
          ></v-select>
          <v-text-field
            v-model="subject"
            outlined
            dense
            :counter="40"
            :rules="Rules.subjectRules"
            :label="$t('Contact_Subject')"
            :color="color"
            required
          ></v-text-field>
          <v-textarea
            outlined
            dense
            v-model="body"
            :counter="500"
            :rules="Rules.bodyRules"
            label=""
            :color="color"
            required
          ></v-textarea>

          <v-spacer class="contact-spacer"></v-spacer>

          <v-btn
            depressed
            x-large
            block
            :loading="loading"
            :disabled="!valid"
            :color="colorBtn"
            :dark="valid"
            class="mr-4"
            @click="validate && sendEmail()"
          >
            {{ $t("Contact_SendBtn") }}
          </v-btn>

          <Snackbar ref="snackbar" :message="snackbarMessage" />

          <!-- <v-btn color="error" class="mr-4" @click="reset">
          Reset Form
        </v-btn>

        <v-btn color="warning" @click="resetValidation">
          Reset Validation
        </v-btn> -->
        </v-form>
      </v-col>
    </v-row>
    <v-row class="resources-row-bottom"></v-row>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "@/components/contacts/Snackbar.vue";
const url = "https://pages.api.sicomelectronics.com";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    snackbarMessage: "",
    text: "",
    valid: true,
    color: "rgb(0,32,79)",
    colorBtn: "#002050",
    name: "",
    email: "",
    subject: "",
    body: "",
    site: "",
    sites: ["SmartCity", "HomeConnected", "Buildings"],
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setSite() {
      this.site = this.$route.params.site;
    },

    async sendEmail() {
      this.loading = true;
      try {
        const { data } = await axios.post(url + "/api/sendEmail", {
          name: this.name,
          email: this.email,
          subject: this.subject,
          website: this.site,
          message: this.body,
        });
        this.reset();
        this.snackbarMessage = this.$i18n.t("Contact_Success_Message");
        this.$refs.snackbar.toggleSnack(true);
        data;
      } catch (error) {
        console.log("error at sendEmail", error);
        this.snackbarMessage = this.$i18n.t("Contact_Failed_Message");
        this.$refs.snackbar.toggleSnack(true);
      }
      this.loading = false;
    },
  },
  computed: {
    Rules() {
      return {
        nameRules: [
          (v) => !!v || this.$i18n.t("Contact_Rule_Name"),
          (v) => (v && v.length <= 20) || this.$i18n.t("Contact_Rule_Name2"),
        ],
        emailRules: [
          (v) => !!v || this.$i18n.t("Contact_Rule_Email"),
          (v) => /.+@.+\..+/.test(v) || this.$i18n.t("Contact_Rule_Email2"),
        ],
        subjectRules: [
          (v) => !!v || this.$i18n.t("Contact_Rule_Subject"),
          (v) => (v && v.length <= 40) || this.$i18n.t("Contact_Rule_Subject2"),
        ],
        bodyRules: [
          (v) => !!v || this.$i18n.t("Contact_Rule_Body"),
          (v) => (v && v.length <= 500) || this.$i18n.t("Contact_Rule_Body2"),
        ],
      };
    },
    locale() {
      return this.$i18n.locale;
    },
  },

  created() {
    this.site = this.$route.params.site;
  },

  watch: {
    locale(val, old) {
      this.validate();
      val;
      old;
    },
  },
};
</script>
<style>
.resources-row-top {
  margin-top: 3.9vw;
  background-color: #ffffff !important;
}
.resources-row-bottom {
  margin-bottom: 1.56vw;
  background-color: #ffffff !important;
}
.contact-title-span {
  color: #113353 !important;
  font-weight: 500 !important;
  font-size: 26px !important;
  letter-spacing: 0px;
}
.contact-spacer {
  height: 30px;
}
/* hover color */
.v-select-list .v-list-item:hover {
  background-color: rgb(239, 239, 239);
}
/* Selected element color on a v-select input */
.v-select__selection {
  color: rgb(0, 32, 79) !important;
}
</style>
