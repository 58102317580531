<template>
  <div>
    <!-- <TopImage /> -->
    <Contact />
  </div>
</template>
<script>
import Contact from "@/components/contacts/Index.vue";
/* import TopImage from "@/components/contacts/TopImage.vue"; */
export default {
  name: "ViewContact",
  components: {
    Contact,
    /* TopImage, */
  },
};
</script>
