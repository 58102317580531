<template>
  <v-row class="resources-color-row">
    <v-col cols="11" class="mb-6 resources-col">
      <v-row class="mb-8">
        <v-col
          v-bind:class="{
            'text-start': titleAlignment == 'start',
            'text-center': titleAlignment == 'center',
          }"
        >
          <span class="resources-span-title">{{ title }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          data-aos-once="false"
          data-aos="fade-in"
          data-aos-duration="500"
          data-aos-delay="550"
          v-bind:class="{
            'text-start': item.align == 'start',
            'text-center': item.align == 'center',
            'text-end': item.align == 'end',
          }"
          md="4"
          sm="12"
          v-for="(item, i) in resourcesShow"
          :key="i"
        >
          <span @click="click(item)" class="resources-span-text">{{
            item.details.name
          }}</span>
        </v-col>
      </v-row>

      <Modal ref="modal" :resource="resourceSelected" />

      <template v-if="!lastItem">
        <v-divider class="mt-16"></v-divider>
      </template>
    </v-col>
  </v-row>
</template>
<style>
span:visited {
  color: red;
}
span:active fieldset {
  color: red !important;
}
</style>
<script>
import Modal from "@/components/sections/ModalDoc.vue";
import axios from "axios";
const url = "https://pages.api.sicomelectronics.com";
export default {
  name: "Section",
  components: {
    Modal,
  },

  props: {
    title: String,
    resources: Array,
    firstItem: Boolean,
    lastItem: Boolean,
    titleAlignment: String,
  },

  data: () => ({
    resourceSelected: undefined,
  }),
  created() {
    console.log(this.$vuetify.breakpoint);
  },
  /* mounted() {
    const doc = this.getDocumentTypes();
    doc;
  }, */

  methods: {
    async click(item) {
      const docs = await this.getDocuments(item.product_id);
      this.resourceSelected = docs;
      this.$refs.modal.toggleModal(true);
    },

    async getDocuments(id_product) {
      try {
        const { data } = await axios.get(url + "/api/documents", {
          params: {
            product_id: id_product,
          },
        });
        const documents = data.data;
        const documentsToShow = documents.map((document) => {
          const {
            document_id,
            document_type,
            document_section,
            document_details,
          } = document;
          const documentDetailToShow = document_details.find(
            (detail) => detail.language_slug
          );
          const documentTypeDetailToShow = document_type.document_type_details.find(
            (item) => item.language_slug === this.$i18n.locale
          );
          return {
            document_id,
            document_type: documentTypeDetailToShow,
            document_section,
            document_details: documentDetailToShow,
          };
        });

        return documentsToShow.filter((item) => item.document_details);
      } catch (error) {
        console.log("error at getdocuments", error);
      }
    },
  },
  computed: {
    resourcesShow() {
      const lang = this.$i18n.locale;
      /* console.log(lang); */
      const r = this.resources.map((item) => {
        const { product_id, details } = item;
        const detailProd = details.find((item) => item.language_slug === lang);
        /* console.log(item); */
        return { product_id, details: detailProd };
      });
      return r;
    },
  },
};
</script>
<style>
@media screen and (max-width: 769px) {
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media screen and (max-width: 427px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.resources-row-top {
  margin-top: 3.9vw;
}
.resources-row-bottom {
  margin-bottom: 1.56vw;
}
.resources-color-row {
  margin-left: 0.49vw;
  margin-right: 0.49vw;
}
.resources-col {
  margin-left: 6.9vw;
  padding-right: 6.5vw !important;
}
.resources-span-title {
  color: #113353 !important;
  font-weight: 900;
  font-size: 25px;
}
.resources-span-text {
  cursor: pointer;
  text-transform: uppercase !important;
  color: #797979 !important;
  font-weight: 500;
  font-size: 23px;
}

@media screen and (max-width: 1600px) {
  .resources-span-title {
    color: #113353 !important;
    font-weight: 900;
    font-size: 1.563vw;
  }
  .resources-span-text {
    cursor: pointer;
    text-transform: uppercase !important;
    color: #797979 !important;
    font-weight: 500;
    font-size: 1.438vw;
  }
}

@media screen and (max-width: 769px) {
  .resources-span-title {
    color: #113353 !important;
    font-weight: 900;
    font-size: 22px;
  }
  .resources-span-text {
    cursor: pointer;
    text-transform: uppercase !important;
    color: #797979 !important;
    font-weight: 500;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .resources-span-title {
    color: #113353 !important;
    font-weight: 900;
    font-size: 2.865vw;
  }
  .resources-span-text {
    cursor: pointer;
    text-transform: uppercase !important;
    color: #797979 !important;
    font-weight: 500;
    font-size: 2.344vw;
  }
}

@media screen and (max-width: 426px) {
  .resources-span-title {
    color: #113353 !important;
    font-weight: 900;
    font-size: 20px;
  }
  .resources-span-text {
    cursor: pointer;
    text-transform: uppercase !important;
    color: #797979 !important;
    font-weight: 500;
    font-size: 16px;
  }
}

@media screen and (max-width: 424px) {
  .resources-span-title {
    color: #113353 !important;
    font-weight: 900;
    font-size: 4.695vw;
  }
  .resources-span-text {
    cursor: pointer;
    text-transform: uppercase !important;
    color: #797979 !important;
    font-weight: 500;
    font-size: 3.756vw;
  }
}
</style>
