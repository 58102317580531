<template>
  <div>
    <v-app-bar class="application-bar" flat height="85">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="2" :class="responsiveLogoStyle2">
          <v-img
            v-if="!hideLogo"
            :class="[responsiveLogoStyle, `shrink `]"
            contain
            @click="$router.push('/')"
            :src="responsiveLogoImg"
          ></v-img>
        </v-col>

        <v-spacer></v-spacer>

        <v-col v-if="hideMenu" class="category-col" cols="auto">
          <v-btn
            v-for="(item, index) in menuBars.items"
            :key="index"
            text
            color="transparent"
          >
            <span class="header-menu-btn">
              {{ item.title }}
            </span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col v-if="!hideLang" class="category-col" cols="auto">
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="language-btn" text v-bind="attrs" v-on="on">
                  <v-icon
                    class="web-language-icon"
                    size="22px"
                    :style="{ color: colorIcon }"
                  >
                    mdi-web
                  </v-icon>
                  <span
                    v-if="responsiveLangText"
                    class="language-text"
                    :style="{ color: colorText }"
                  >
                    {{ currentLang }}
                  </span>
                  <v-icon size="14px" :style="{ color: colorText }"
                    >mdi-chevron-down</v-icon
                  >
                </v-btn>
              </template>
              <v-list class="language-list">
                <v-list-item
                  class="language-list-item"
                  v-for="(item, index) in globalLanguages.items"
                  :key="index"
                  link
                  hide-details
                  @click="changeLang(item)"
                >
                  <v-list-item-title
                    class="fontSicom language-list-item-title"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto" class="search-btn-margin category-col">
          <v-btn
            v-if="!hideSearchBtn && !active"
            @click="active = true"
            class="search-btn"
            text
            icon
          >
            <eva-icon
              name="search"
              animation="pulse"
              fill="rgb(49,97,162)"
            ></eva-icon>
          </v-btn>
          <v-col v-if="active" class="search-textfield">
            <v-text-field
              v-if="active"
              v-click-outside="onClickOutside"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :color="colorIcon"
              outlined
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-col>

        <v-col class="end-col" cols="auto"></v-col>
      </v-row>
    </v-app-bar>
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    logo: {
      default: require("@/assets/SICOM2020.png"),
      require: false,
    },
    logo2: {
      default: require("@/assets/sicomlogomin.png"),
      require: false,
    },
    hideSearchBtn: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLogo: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideMenu: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLang: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data: () => ({
    number: 100,
    username: undefined,
    active: false,
    search: "",
    currentLang: "Español",
    userMenuItems: [
      {
        text: "Instance Details",
        icon: "mdi-magnify",
        slug: "details",
      },
      {
        text: "Instance Configuration",
        icon: "mdi-wrench",
        slug: "generalConfig",
      },
      {
        text: "Account Settings",
        icon: "mdi-account-box",
        slug: "myAccount",
      },
    ],
    /* menuBars: [
      { title: "Products" },
      { title: "Shop" },
    ], */
    /* cards: [
      {
        title: "Cámaras",
        src: require("@/assets/img/image3_min.png"),
        width: "43px",
        height: "64px",
        flex: 1,
        slug: "Camera",
      },
      {
        title: "Video Timbres",
        src: require("@/assets/img/image_min.png"),
        width: "26px",
        height: "64px",
        flex: 2,
        slug: "hola",
      },
      {
        title: "Smart NVR",
        src: require("@/assets/img/image1_min.png"),
        width: "64px",
        height: "64px",
        subtitle: "nuevo",
        flex: 2,
        slug: "hola2",
      },
    ], */
  }),

  computed: {
    globalLanguages() {
      return {
        items: [
          { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
          { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
        ],
      };
    },

    menuBars() {
      return {
        items: [
          { title: this.$i18n.t("Home_Connected") },
          { title: this.$i18n.t("Smart_City") },
          { title: this.$i18n.t("Smart_Utility") },
        ],
      };
    },

    colorIcon() {
      return this.color
        ? this.color + "!important"
        : "rgb(49,97,162) !important";
    },
    colorText() {
      return this.textColor
        ? this.textColor + "!important"
        : "rgb(41, 73, 99) !important";
    },
    responsiveHeight() {
      return this.$vuetify.breakpoint.smAndUp ? 85 : 68;
    },
    responsiveLogoImg() {
      return this.$vuetify.breakpoint.smAndUp ? this.logo : this.logo2;
    },
    responsiveLogoStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? `logoSicom-smAndUp`
        : `logoSicom-smAndDown`;
    },
    responsiveLogoStyle2() {
      return this.$vuetify.breakpoint.smAndUp
        ? `logoSicom-col`
        : `logoSicom-col-smAndDown`;
    },
    responsiveLangText() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang.value;
      const arrLang = [
        { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
        { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
      ];
      const language = arrLang.find((item) => item.value === lang.value);
      this.currentLang = language.title;
    },
    navigate(route) {
      this.$router.replace({ name: route });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onClickOutside() {
      this.active = false;
    },
  },
};
</script>

<style scoped>
.fontSicom {
  font-family: DM Sans;
}
.application-bar {
  background-color: rgb(255, 255, 255) !important;
}
.logoSicom-col {
  justify-content: center;
  align-items: center;
}
.logoSicom-col-smAndDown {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoSicom-smAndUp {
  cursor: pointer;
  width: 140px;
  height: 65px;
}
.logoSicom-smAndDown {
  cursor: pointer;
  width: 140px;
  height: 65px;
}
.category-col {
}
.web-language-icon {
  margin-top: 0.104vw;
  margin-right: 0.104vw;
}
.language-btn {
  text-transform: capitalize;
  padding-left: 0.83vw !important;
  padding-right: 0.83vw !important;
}
.language-text {
  font-family: DM Sans;
  font-weight: 700;
  font-size: 16px !important;
  letter-spacing: 0vw;
}
.language-list-item {
  min-height: 1.875vw !important;
}
.language-list {
  border-radius: 0vw !important;
  max-height: auto !important;
}
.language-list-item-title {
  font-family: DM Sans;
  font-size: 14px !important;
}
.search-btn {
  padding-top: 0.156vw;
}
.search-btn-margin {
  margin-left: 0.208vw;
  margin-right: 0.208vw;
}
.search-textfield {
  width: 160px;
}
.end-col {
  margin-right: 0px;
}
.ripple-color {
  color: green !important;
  caret-color: blue !important;
}

.header-menu-style {
  background-color: white;
  padding: 0px;
  margin-top: 23px;
}
.products-menu-col {
  height: 180px;
  width: 200px;
  padding: 0px;
  border: 1px solid white;
}
.products-menu-col-min {
  padding: 0px;
  height: 100px;
  width: 100px;
  padding-top: 18px;
  margin-top: 33px;
}
.products-menu-text {
  padding: 0px;
  margin-top: 15px;
  line-height: 1;
}
.products-menu-title {
  font-size: 15px;
  color: #707070;
  text-transform: capitalize;
}
.products-menu-subtitle {
  font-size: 15px;
  color: #1998d5;
  text-transform: none;
}
.header-menu-btn {
  color: #707070;
}
</style>
<style scoped>
.effect:hover {
  font-weight: 600;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px 29px !important;
}
</style>
