<template>
  <div>
    <v-row no-gutters class="resources-row">
      <v-col
        data-aos-once="false"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="1100"
        cols="12"
        class="resources-col-home"
      >
        <span class="resources-span">{{ $t("Resources_Title") }}</span>
      </v-col>
    </v-row>
    <v-row class="resources-row-top"></v-row>
    <div v-for="section in sectionsShow" :key="section.section_id">
      <v-row no-gutters>
        <Item
          :title="section.title"
          titleAlignment="start"
          :resources="section.products"
        />
      </v-row>
    </div>
    <v-row class="resources-row-bottom"></v-row>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
const url = "https://pages.api.sicomelectronics.com";
AOS.init();
import Item from "@/components/sections/Index.vue";
export default {
  name: "Home",
  components: {
    Item,
  },
  data: function() {
    return {
      products: [],
      sections: [],
      title: [],
    };
  },

  async created() {
    /* this.products = await this.getProducts(); */
    this.sections = await this.getSections();
  },

  computed: {
    sectionsShow() {
      const section = this.sections.map((section) => {
        const {
          id,
          section_details,
          related_documents,
          related_products,
        } = section;
        const sectionsToShow = section_details.find(
          (item) => item.language_slug === this.$i18n.locale
        );
        let products = [];
        const relatedDocumentToShow = related_documents.map((item) => {
          const relatedProductsToShow = item.related_products.forEach(
            (item) => {
              const details = item.find(
                (item) => item.language_slug === this.$i18n.locale
              );
              if (details) {
                products.push({
                  id: details.product_id,
                  text: details.name,
                });
              }
            }
          );
          return relatedProductsToShow;
        });
        /* const productsToShow = this.filterProducts(products); */
        /* console.log(this.productsToShow); */
        if (sectionsToShow) {
          return {
            section_id: id,
            title: sectionsToShow ? sectionsToShow.title : "",
            section_details: sectionsToShow ? sectionsToShow : "",
            related_documents: relatedDocumentToShow,
            products: related_products,
          };
        }
      });

      return section.filter((item) => item);
    },
  },

  methods: {
    /* async getProducts() {
      try {
        const { data } = await axios.get(url + "/api/products");
        const products = data.data;
        const productsToShow = products.map((product) => {
          const {
            product_id,
            models,
            application_ids,
            tags_nickname,
            uses,
            documents,
            product_details,
          } = product;

          return {
            product_id,
            models,
            application_ids,
            tags_nickname,
            uses,
            documents,
            product_details,
          };
        });
        /* console.log(productsToShow); */
    /* return productsToShow;
      } catch (error) {
        console.log("error at getproducts", error);
      }
    }, */

    async getSections() {
      try {
        const { data } = await axios.get(url + "/api/documentSections");
        const sections = data.data;
        const sectionsToShow = sections.map((section) => {
          const {
            id,
            related_documents,
            section_details,
            related_products,
          } = section;
          return {
            id,
            related_documents,
            section_details,
            related_products,
          };
        });
        return sectionsToShow;
      } catch (error) {
        console.log("error at getsections", error);
      }
    },

    filterProducts(products) {
      const filteredArr = products.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filteredArr;
    },
  },
};
</script>
<style>
.resources-row-top {
  margin-top: 3.9vw;
  background-color: red !important;
}
.resources-row-bottom {
  margin-bottom: 1.56vw;
  background-color: red !important;
}
.resources-row {
  background-color: #002050;
  height: 26.042vw;
}
.resources-col-home {
  margin-top: 9.44vw !important;
  margin-bottom: 9.44vw !important;
}
.resources-span {
  font-size: 87px !important;
  margin-left: 87px;
  color: #ffffff !important;
  font-weight: normal;
}

@media screen and (max-width: 1600px) {
  .resources-span {
    font-size: 5.438vw !important;
    margin-left: 5.85vw;
    color: #ffffff !important;
    font-weight: normal;
  }
}
@media screen and (max-width: 769px) {
  .resources-span {
    font-size: 42px !important;
    margin-left: 5.85vw;
    color: #ffffff !important;
    font-weight: normal;
  }
  .resources-row {
    background-color: #002050;
    height: 210px;
  }
}
@media screen and (max-width: 767px) {
  .resources-span {
    font-size: 5.469vw !important;
    margin-left: 5.85vw;
    color: #ffffff !important;
    font-weight: normal;
  }
  .resources-row {
    background-color: #002050;
    height: 27.308vw;
  }
}
@media screen and (max-width: 426px) {
  .resources-span {
    font-size: 36px !important;
    margin-left: 5.85vw;
    color: #ffffff !important;
    font-weight: normal;
  }
  .resources-row {
    background-color: #002050;
    height: 135px;
  }
}
@media screen and (max-width: 424px) {
  .resources-span {
    font-size: 8.451vw !important;
    margin-left: 5.85vw;
    color: #ffffff !important;
    font-weight: normal;
  }
  .resources-row {
    background-color: #002050;

    height: 31.69vw;
  }
}
</style>
