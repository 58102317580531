import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify/lib";
import router from "./router";
import "@mdi/font/css/materialdesignicons.css";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";
import EvaIcons from "vue-eva-icons";
import i18n from "./i18n";

Vue.use(EvaIcons);
Vue.config.productionTip = false;
Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { es, en },
    current: "es",
  },
  icons: {
    iconfont: "mdi",
  },
  breakpoint: {
    thresholds: {
      xs: 427,
      sm: 769,
      md: 1600,
      lg: 1920,
    },
    scrollBarWidth: 10,
  },
};

new Vue({
  vuetify: new Vuetify(opts),
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
