<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1152px">
      <v-card>
        <v-card-title>
          <span class="headline modal-span">{{ $t("Modal_Title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              v-model="selected"
              show-select
              item-key="document_details.document_details_id"
              :headers="headersTable.headers"
              :items="resource"
              hide-default-footer
              disable-pagination
              no-results-text="Sin Documentos"
              no-data-text="Sin Documentos"
            >
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn"
            color="rgb(60,104,169)"
            dark
            @click="dialog = false"
          >
            {{ $t("Modal_Cancel_Button") }}
          </v-btn>
          <v-btn
            :disabled="!selected.length"
            class="modal-btn"
            color="rgb(60,104,169)"
            :dark="!!selected.length"
            @click="getDownload()"
          >
            {{ $t("Modal_Download_Button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
const url = "https://pages.api.sicomelectronics.com";
export default {
  name: "Modal",
  props: {
    resource: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    enabled: false,
    singleSelect: false,
    selected: [],
  }),

  methods: {
    toggleModal(val) {
      this.dialog = val;
    },
    downloadBtn() {
      this.getDownload();
      this.dialog = false;
    },

    getDownload() {
      this.selected.map((item) => {
        axios({
          url: url + item.document_details.file_url,
          method: "GET",
          headers: {
            Authorization: "Bearer sicom",
          },
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.document_details.name);
          document.body.appendChild(link);
          link.click();
        });
      });
    },
  },

  computed: {
    headersTable() {
      return {
        headers: [
          {
            text: this.$i18n.t("Type_Doc_Modal"),
            value: "document_type.name",
            sortable: false,
            class: "color-header",
          },
          {
            text: this.$i18n.t("Name_Doc_Modal"),
            value: "document_details.title",
            sortable: false,
            class: "color-header",
          },
          {
            text: this.$i18n.t("Format_Doc_Modal"),
            value: "document_details.file_format",
            sortable: false,
            class: "color-header",
          },
          {
            text: this.$i18n.t("Language_Doc_Modal"),
            value: "document_details.language_slug",
            sortable: false,
            class: "color-header",
          },
        ],
      };
    },
  },
};
</script>
<style>
.modal-span {
  font-family: "DM Sans" !important;
  color: #113353 !important;
  font-weight: 900 !important;
  font-size: 24px !important;
  letter-spacing: 0px;
}
.color-header {
  font-family: "DM Sans";
  color: rgb(0, 32, 79) !important;
  font-size: 14px !important;
}
</style>
